<template>
  <div class="copyright-notice">
    <div class="note-title">版权声明：</div>
    <div class="note-content">
      本报告包含的所有内容（包括但不限于文本、数据、图片、图标、logo等）都受到商标法、知识产权法的保护，归苏州才干智因科技有限公司所有。未经苏州才干智因科技有限公司书面许可，任何人不得使用、修改、复制、翻译、公开及出售任何与本报告有关的内容及形式。
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
.copyright-notice {
  margin: 24px 0px 20px 0px;
  padding: 16px;
  background: #F5F5F5;
  border-radius: 8px;
  font-size: 14px;
  .note-title {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    line-height: 22px;
  }

  .note-content {
    margin-top: 4px;
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    line-height: 20px;
  }
}
</style>