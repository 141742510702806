<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="video-problem-personal">
    <template v-if="isL5Position">
      <question-item-l5 v-for="(item, index) in questionList" :key="index" :data="item" :is-l5-position="isL5Position" :mockType="mockType"></question-item-l5>
    </template>
    <template v-else>
      <question-item v-for="(item, index) in questionList" :key="index" :data="item" :is-l5-position="isL5Position"></question-item>
    </template>
  </div>
</template>

<script>
import QuestionItem from './question-item.vue'
import QuestionItemL5 from './question-item-l5.vue'
import { l2Dimensions } from '@/assets/js/keyToName' // 得分维度

export default {
  name: 'VideoProblemPersonal',
  components: {
    QuestionItem,
    QuestionItemL5
  },
  props: {
    // 题目信息
    answerResult: {
      type: Array,
      default: () => [],
    },
    // 候选人的回答
    answerInfos: {
      type: Array,
      default: () => [],
    },
    isL5Position: {
      type: Boolean,
      default: false,
    },
    mockType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      questionList: [], // 题目信息
    }
  },
  watch: {
    answerResult: {
      deep: true,
      immediate: true,
      handler() {
        this.getQuestionList()
      },
    },
  },
  methods: {
    getQuestionList() {
      const questionList = []
      this.answerResult.forEach((list, index) => {
        console.log('list-----',list.length > 1?true:false)
        let isHaveProbe =  list.length > 1?true:false
        list.forEach((questionInfo, idx) => {
          if (!this.isL5Position && idx > 0) {
            return
          }
          const isProbe = idx === 1
          const tempStr = questionInfo.question.problem
          const [videoInfo = {}] = questionInfo.video_url || []
          let innerText = tempStr
          const innerImage = []
          const innerVideo = []
          if (innerText) {
            const parser = document.createElement('div')
            parser.innerHTML = innerText
            parser.querySelectorAll('video').forEach(video => {
              const src = video.getAttribute('src')
              if (src) innerVideo.push(src)
              video.querySelectorAll('source').forEach(source => {
                innerVideo.push(source.getAttribute('src'))
              })
              video.parentElement?.removeChild(video)
            })
            parser.querySelectorAll('img').forEach(img => {
              const src = img.getAttribute('src')
              if (src) innerImage.push(src)
              img.parentElement?.removeChild(img)
            })
            // 移除第一个空白p元素
            const firstP = parser.querySelector('p')
            if (firstP && !firstP.innerText) {
              firstP?.remove()
            }
            innerText = parser.innerHTML
            // 最后再添加序号和分值
            if (innerText && /<p.*?>/.test(innerText)) {
              innerText = innerText.replace(/<p.*?>/, '<p>' + (isProbe ? '(AI追问) ' : index + 1 + '.'))
            } else {
              innerText = (isProbe ? '(AI追问) ' : index + 1 + '.') + innerText
            }
          }
          // 寻找对应的答案信息
          const answerInfo = this.answerInfos.find(a => {
            return a.problemAnswerId === questionInfo.question?.problemAnswerId
          })
          // 寻找对应的得分维度
          const realL2Dimensions = this.getRealL2Dimensions(answerInfo)
          // 附件列表
          const answerFileList = questionInfo.question?.answerFileList || []

          questionList.push({
            isHaveProbe,// 是否有追问
            isProbe,
            innerText,
            innerImage,
            innerVideo,
            showDetail: false, // 是否显示原题目
            videoInfo, // 回答视频
            answerInfo,
            realL2Dimensions,
            answerFileList,
            answerSuggest: questionInfo.answerSuggest,
            standardAnswer: questionInfo.standardAnswer,
            sameQuestion: questionInfo.sameQuestion,
          })
        })
      })
      this.questionList = questionList
    },

    // 获取得分维度，copy from rate-log.vue
    getRealL2Dimensions(currentAnserInfo) {
      const languages = ['', 'L2-普通话', 'L2-英语']
      const infos = ['', '测试候选人普通话水平能力，以中文识别候选人回答内容。', '测试候选人英语水平能力，以英文识别候选人回答内容。']
      const arr = l2Dimensions
        .map(item => {
          const keys = item.keys
          const obj = {}
          keys.forEach(k => {
            obj[k] = currentAnserInfo ? currentAnserInfo[k] : 0
          })
          let label = ''
          let info = ''
          if (item.key === 'language') {
            label = languages[obj[item.key]]
            info = infos[obj[item.key]]
          } else {
            label = item.label
            info = item.info
          }
          return { ...item, ...obj, label, info }
        })
        .filter(f => f[f.key])
      return arr
    },
  },
}
</script>

<style lang="scss" scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
</style>
