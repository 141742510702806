<template>
  <div :class="['cards',data.innerText.indexOf('(AI追问)')!=-1?'pb-0':'']">
    <div class="cardtop" v-if="mpSource == 1 && !data.isProbe">
      <img src="../../assets/khimg.png" alt="">
      <span class="titl">考核胜任力：</span>
      <span class="tet">{{ data.answerInfo.assessmentPoint }}</span>
    </div>

    <div class="carcen" v-show="mpSource == 1">
      <div class="carcentop" v-if="data.answerSuggest">
        <img src="../../assets/jyimg.png" alt="">
        <span class="tite">答题建议</span>
        <img src="../../assets/jyimg.png" alt="">
      </div>
      <div class="suggest" v-if="data.answerSuggest">
        <pre class="word" v-html="data.answerSuggest"></pre>
        <div class="mask flex" v-if="jiami">
          <div class="unlock flex" @click="ulbtn">
            <img src="../../assets/lock.png" alt="">
            <span>立即解锁查看</span>
          </div>
        </div>
      </div>
      <template v-if="data.standardAnswer">
        <div class="carcentop">
          <img src="../../assets/jyimg.png" alt="">
          <span class="tite">回答范文</span>
          <img src="../../assets/jyimg.png" alt="">
        </div>
        <div class="suggest">
          <pre class="word" v-html="data.standardAnswer"></pre>
          <div class="mask flex" v-if="jiami">
            <div class="unlock flex" @click="ulbtn">
              <img src="../../assets/lock.png" alt="">
              <span>立即解锁查看</span>
            </div>
          </div>
        </div>
      </template>
      <template v-if="data.sameQuestion">
        <div class="carcentop">
          <img src="../../assets/jyimg.png" alt="">
          <span class="tite">其他类似问题</span>
          <img src="../../assets/jyimg.png" alt="">
        </div>
        <div class="suggest">
          <pre class="word" v-html="data.sameQuestion"></pre>
          <div class="mask flex" v-if="jiami">
            <div class="unlock flex" @click="ulbtn">
              <img src="../../assets/lock.png" alt="">
              <span>立即解锁查看</span>
            </div>
          </div>
        </div>
      </template>
    </div>   
    <div v-if="!data.isProbe" style="height: 40px;width: 100%;"></div>
    <template v-if="data.innerText.indexOf('(AI追问)')==-1">
      <div class="issue">
        <div class="lab">问题</div>
        <div class="iscot" v-html="data.innerText.slice(3)">
          <!-- {{ data.innerText.slice(2) }} -->
        </div>
      </div>
    </template>
    <template v-else>
      <div :style="{'height': isMobile?'42px':'62px'}"></div>
      <div class="issue">
        <div class="lab">AI追问</div>
        <div class="iscot" v-html="data.innerText.replace('(AI追问)','')">
          <!-- {{ data.innerText.replace('(AI追问)','') }} -->
        </div>
      </div>
    </template>
    <div class="answer">
      <div class="lab">我的回答</div>
      <div class="iscot">
        <div ref="myansw" :class="['word',showExp&&!isExpand?'heightShrink':'']" v-if="data.answerInfo.answerText">
          {{ data.answerInfo.answerText }}
        </div>
        <div :class="['playback',!isMobile?'koyou':'']">
          <div class="pbkbtn flex" @click="handelPbk(data.videoInfo)" v-if="mockType == 0">
            <span>回放视频</span>
            <img src="../../assets/playBack.png" alt="">
          </div>
          <div v-else></div>
          <div class="expand" @click="handelExp" v-if="showExp">
            <span v-show="isExpand">收起</span>
            <span v-show="!isExpand">展开</span>
            <img :class="[isExpand?'reversal':'']" src="../../assets/unfold.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="carcen" v-show="mpSource != 1">
      <div class="carcentop" v-if="data.answerSuggest">
        <img src="../../assets/jyimg.png" alt="">
        <span class="tite">答题建议</span>
        <img src="../../assets/jyimg.png" alt="">
      </div>
      <div class="suggest" v-if="data.answerSuggest">
        <pre class="word" v-html="data.answerSuggest"></pre>
        <div class="mask flex" v-if="jiami">
          <div class="unlock flex" @click="ulbtn">
            <img src="../../assets/lock.png" alt="">
            <span>立即解锁查看</span>
          </div>
        </div>
      </div>
      <template v-if="data.standardAnswer">
        <div class="carcentop">
          <img src="../../assets/jyimg.png" alt="">
          <span class="tite">回答范文</span>
          <img src="../../assets/jyimg.png" alt="">
        </div>
        <div class="suggest">
          <pre class="word" v-html="data.standardAnswer"></pre>
          <div class="mask flex" v-if="jiami">
            <div class="unlock flex" @click="ulbtn">
              <img src="../../assets/lock.png" alt="">
              <span>立即解锁查看</span>
            </div>
          </div>
        </div>
      </template>
      <template v-if="data.sameQuestion">
        <div class="carcentop">
          <img src="../../assets/jyimg.png" alt="">
          <span class="tite">其他类似问题</span>
          <img src="../../assets/jyimg.png" alt="">
        </div>
        <div class="suggest">
          <pre class="word" v-html="data.sameQuestion"></pre>
          <div class="mask flex" v-if="jiami">
            <div class="unlock flex" @click="ulbtn">
              <img src="../../assets/lock.png" alt="">
              <span>立即解锁查看</span>
            </div>
          </div>
        </div>
      </template>
    </div>
    <van-overlay :show="showOly" :lock-scroll="true" @click="closeOly">
      <video-player v-if="showOly&&(data.videoInfo.video_url_mp4 || data.videoInfo.video_url)" ref="ExVideoPlayer" :playsinline="true" class="video-player-banner" :options="getVideoOption(data)"> </video-player>
    </van-overlay>

  </div>
</template>

<script>
// import AnswerText from '@/components/answer-text' // TA的回答
import wx from 'weixin-js-sdk'
export default {
  // components: {
  //   AnswerText,
  // },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
    isL5Position: {
      type: Boolean,
      default: false,
    },
    mockType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isMobile: true,
      jiami: false,
      isExpand: false,
      showExp: false,
      showOly: false,
      isFrom: '', // 来自那个
      mpSource: '1', // source定义 1胜任力(需要显示胜任力)  2名企 3是业务
    }
  },
  computed: {
    getVideoOption() {
      return item => {
        return {
          playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
          autoplay: false, // 如果为true,浏览器准备好时开始回放。
          muted: true, // 默认情况下将会消除任何音频。
          loop: false, // 是否视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          // aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [
            {
              type: 'video/mp4', // 类型
              src: item.videoInfo?.video_url_mp4 || item.videoInfo?.video_url, // url地址
            },
          ],
          poster: item.videoInfo?.video_screenshot_url, // 封面地址
          notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true, // 当前时间和持续时间的分隔符
            durationDisplay: true, // 显示持续时间
            remainingTimeDisplay: false, // 是否显示剩余时间功能
            fullscreenToggle: true, // 是否显示全屏按钮
          },
        }
      }
    },
  },
  mounted() {
    this.isMobile = /(mobile|nokia|iphone|ipad|android|samsung|htc|blackberry)/.test(navigator.userAgent.toLowerCase());
    if(this.$refs.myansw&&this.$refs.myansw.offsetHeight>66) {
      this.showExp = true
    }

    let isFrom = this.$route.query?.isFrom
    this.mpSource = this.$route.query?.mpSource || '1'
    if(isFrom && isFrom === 'miniapp'){
      this.isFrom = 'miniapp'
      if(this.$route.query?.haveAuth === '0') this.jiami = true

    }
  },
  methods: {
    ulbtn() {
      wx.miniProgram.reLaunch({
        url: '/pages/my/index?buy=1'
      })
      // wx.miniProgram.postMessage({data:1})
    },
    handelPbk(vi) {
      console.log('视频回放',vi);
      this.showOly = true;
    },
    closeOly() {
      this.showOly = false;
    },
    handelExp() {
      this.isExpand = !this.isExpand;
    }
  }
}
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/.video-player {
  width: 100%;
  .video-js {
    width: 100%;
    height: 200px;
    video {
      width: 100%;
    }
    .vjs-big-play-button {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.video-problem-personal {
  .cards {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-radius: 16px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.5);
    .cardtop {
      height: 58px;
      padding: 17px 16px;
      display: flex;
      align-items: center;
      background: linear-gradient(180deg, #EAEFFF 0%, rgba(255, 255, 255, 0.5) 100%);
      img {
        width: 24px;
        margin-right: 8px;
      }
      .titl {
        color: rgba(0, 12, 33, 0.65);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
      .tet {
        color: #000C21;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
      }
    }
    .carcen {
      margin: 16px;
      overflow: hidden;
      border-radius: 16px;
      border: 1px solid rgba(0, 12, 33, 0.08);
      .carcentop {
        height: 48px;
        display: flex;
        padding: 0 16px;
        align-items: center;
        background: #FFF;
        background: linear-gradient(90deg, rgba(248, 227, 198, 0.5) 0%, rgba(242, 189, 116, 0.7) 100%);;
        img {
          width: 16px;
        }
        .tite {
          color: #935C19;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          margin: 0 4px;
        }
      }
      .suggest {
        color: #CD9955;
        text-align: justify;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; 
        padding: 12px 16px;
        position: relative;
        overflow: hidden;
        border-radius: 0 0 16px 16px;
        .word {
          white-space: break-spaces;
        }
        .mask {
          width: 100%;
          height: 100%;
          border-radius: 0 0 16px 16px;
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
          backdrop-filter: blur(4px);
          -webkit-backdrop-filter: blur(4px);
          // background: rgba(255, 255, 255, 0.45);
          .unlock {
            height: 30px;
            padding: 0 12px;
            border-radius: 38px;
            background: linear-gradient(90deg, rgba(248, 227, 198, 0.5) 0%, rgba(242, 189, 116, 0.7) 100%);;
            img {
              width: 14px;
            }
            span {
              color: #744E1F;
              text-align: justify;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              margin-left: 6px;
            }
          }
        }
      }
    }
    .issue {
      margin: 0 16px;
      margin-bottom: 30px;
      border-radius: 16px;
      background: rgba(249, 250, 252, 0.9); //#F4F7FF;
      position: relative;
      .lab {
        height: 28px;
        padding: 0 12px;
        position: absolute;
        left: 0;
        top: -14px;
        text-align: center;
        line-height: 28px;
        background: linear-gradient(225deg, #033FFF 0%, #48A7FF 100%);
        border-radius: 16px 16px 16px 4px;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }
      .iscot {
        color: rgba(0, 12, 33, 0.85);
        text-align: justify;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        padding: 26px 16px 12px;
      }
    }
    .answer {
      margin: 0 16px;
      border-radius: 16px;
      background: rgba(255, 250, 241, 0.5); //#FFFAF1;
      position: relative;
      .lab {
        width: 88px;
        height: 28px;
        position: absolute;
        right: 0;
        top: -14px;
        text-align: center;
        line-height: 28px;
        background: linear-gradient(225deg, #FFA216 0%, #FFC700 100%);
        border-radius: 16px 16px 4px 16px;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }
      .iscot {
        color: rgba(0, 12, 33, 0.85);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        padding: 26px 16px 12px;
        .word {
          text-align: justify;
          margin-bottom: 16px;
        }
        .heightShrink {
          height: 66px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
        }
        .playback {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .pbkbtn {
            width: 100px;
            height: 30px;
            cursor: pointer;
            border-radius: 36px;
            border: 1px solid #FFA714;
            span {
              color: #FFA714;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              margin-right: 4px;
              position: relative;
              top: 1px;
            }
            img {
              width: 16px;
            }
          }
          .expand {
            display: flex;
            align-items: center;
            cursor: pointer;
            span {
              color: #FFA714;
              font-family: PingFang SC;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              margin-right: 4px;
            }
            img {
              width: 16px;
              height: 16px;
            }
            .reversal {
              transform: rotate(180deg);
            }
          }
        }
        .koyou {
          justify-content: end;
          .pbkbtn {
            margin-right: 16px;
          }
        }
      }
    }
    .cardbot {
      .carditm {
        margin: 0 16px;
        .citop {
          margin-bottom: 13px;
          display: flex;
          align-items: center;
          .category {
            color: #000C21;
            text-align: justify;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
          }
          .scores {
            flex: 1;
          }
          .grade {
            color: rgba(0, 12, 33, 0.65);
            font-size: 14px;
            font-weight: 400;
          }
        }
        .cibot {
          padding: 12px 16px;
          border-radius: 16px 4px 16px 16px;
          background: #F4F7FF;
          .word {
            color: rgba(0, 12, 33, 0.85);
            text-align: justify;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }
          .other {
            padding: 16px;
            border-radius: 8px;
            background: #FFF;
            .ohitm {
              display: flex;
              align-items: center;
              img {
                width: 24px;
                height: 24px;
              }
              .spw {
                color: rgba(0, 12, 33, 0.85);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                margin: 0 16px 0 8px;
              }
              .spl {
                flex: 1;
                margin-right: 16px;
              }
              .spn {
                color: rgba(0, 12, 33, 0.85);
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px; 
              }
            }
          }
        }
      }
    }
  }
  .mt-48 {
    margin-top: -48px;
  }
  .pb-0 {
    padding-bottom:0;
  }
  .video-player-banner {
    height: 100vh;
    display: flex;
    align-items: center;
    /deep/.video-js {
      height: 50vh!important;
    }
  }
}
</style>