<template>
  <div class="problem">
    <template v-if="!data.isProbe && isL5Position">
      <!-- 胜任力 -->
      <div class="problem-capability">
        <p class="title">
          <span>考核胜任力：</span>
          <span>{{ data.answerInfo.assessmentPoint }}</span>
        </p>
      </div>
      <!-- 答题建议 -->
      <div v-if="data.answerSuggest" class="problem-answer-suggest">
        <div class="problem-title suggest-title">答题建议</div>
        <div>
          <pre v-html="data.answerSuggest" class="problem-title" />
        </div>
      </div>
    </template>
    <!-- 题目 -->
    <pre class="problem-title" v-html="data.innerText" />
    <!-- 查看原始题目 -->
    <div v-if="data.innerImage.length || data.innerVideo.length" class="problem-origin">
      <div v-show="data.showDetail" class="problem-origin-detail">
        <!-- 图片 -->
        <div v-if="data.innerImage.length">
          <div v-for="(img, i) in data.innerImage" :key="i + Math.random()" class="inner-img">
            <img :src="img" style="max-width: 100%" />
          </div>
        </div>
        <!-- 视频 -->
        <div v-if="data.innerVideo.length">
          <div v-for="(videoItem, i) in data.innerVideo" :key="videoItem" class="inner-video-player">
            <video
              :id="'parseVideo' + i"
              :ref="'parseVideo' + i"
              width="100%"
              height="200px"
              :src="videoItem"
              controls="controls"
              controlsList="nodownload"
              :poster="videoItem + '?x-oss-process=video/snapshot,t_2000,f_jpg,w_0,h_0,m_fast'"
              webkit-playsinline="true"
              playsinline="true"
              x5-playsinline="true"
              x5-video-player-fullscreen="true"
            >
              您的浏览器不支持 video 标签。
            </video>
          </div>
        </div>
      </div>
      <!-- 折叠控制 -->
      <p class="problem-origin-control" @click="data.showDetail = !data.showDetail">
        <span>{{ data.showDetail ? '收起' : '查看原始题目' }} </span>
        <i :class="data.showDetail ? 'el-icon-arrow-up' : 'el-icon-d-arrow-right'" />
      </p>
    </div>
    <!-- 回答视频 -->
    <div class="problem-video">
      <video-player v-if="data.videoInfo.video_url_mp4 || data.videoInfo.video_url" ref="ExVideoPlayer" :playsinline="true" class="video-player-banner" :options="getVideoOption(data)"> </video-player>
    </div>
    <!-- TA的回答 -->
    <div v-if="data.answerInfo.answerText || data.answerFileList.length" class="problem-answer">
      <AnswerText :answer-text="data.answerInfo.answerText" :key-point-info-result="[]" :attach-list="data.answerFileList" :language="data.answerInfo.language" :assessmentPoint="data.answerInfo.assessmentPoint" />
    </div>
    <!-- 胜任力 -->
    <div v-if="!isL5Position" class="problem-capability">
      <p class="title">
        <span>考核胜任力：</span>
        <span>{{ data.answerInfo.assessmentPoint }}</span>
      </p>
      <p class="desc">
        <span>胜任力说明：</span>
        <span>{{ data.answerInfo.assessmentDesc }}</span>
      </p>
    </div>
    <!-- 得分⭐维度 -->
    <div v-if="!isL5Position" class="problem-score-list">
      <div class="score-item">
        <div class="title">
          <div class="dot" />
          <span>AI得分：</span>
          <van-rate v-model="data.answerInfo.calculateStar" color="#ffd21e" void-icon="star" void-color="#eee" size="16px" readonly></van-rate>
        </div>
      </div>
      <template v-if="data.realL2Dimensions.length">
        <div v-for="(score, i) in data.realL2Dimensions" :key="i" class="score-item">
          <div class="title">
            <div class="dot" />
            <span>{{ score.label }}：</span>
            <!-- <el-rate :value="score[score.scoreKey] || 0" disabled class="inline" /> -->
            <van-rate v-model="score[score.scoreKey]" color="#ffd21e" void-icon="star" void-color="#eee" size="16px" readonly></van-rate>
          </div>
          <div class="desc">
            <span>定义：</span>
            <span>{{ score.info }}</span>
          </div>
        </div>
      </template>
      <div v-else class="score-item">暂无维度</div>
    </div>

    <!-- 答题思路 -->
    <div v-if="!isL5Position" class="problem-idea">
      <!-- 答题思路 -->
      <h5 class="title">答题思路：</h5>
      <p class="text" v-html="data.answerInfo.assessmentIdea" />
    </div>
  </div>
</template>

<script>
import AnswerText from '@/components/answer-text' // TA的回答
export default {
  components: {
    AnswerText,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
    isL5Position: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getVideoOption() {
      return item => {
        return {
          playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
          autoplay: false, // 如果为true,浏览器准备好时开始回放。
          muted: true, // 默认情况下将会消除任何音频。
          loop: false, // 是否视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          // aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [
            {
              type: 'video/mp4', // 类型
              src: item.videoInfo?.video_url_mp4 || item.videoInfo?.video_url, // url地址
            },
          ],
          poster: item.videoInfo?.video_screenshot_url, // 封面地址
          notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true, // 当前时间和持续时间的分隔符
            durationDisplay: true, // 显示持续时间
            remainingTimeDisplay: false, // 是否显示剩余时间功能
            fullscreenToggle: true, // 是否显示全屏按钮
          },
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/.video-player {
  width: 100%;
  .video-js {
    width: 100%;
    height: 200px;
    video {
      width: 100%;
    }
    .vjs-big-play-button {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.video-problem-personal {
  .problem {
    margin-bottom: 25px;
    &-answer-suggest {
      padding: 8px;
      background-color: #fffaf1;
      border-radius: 10px;

      .suggest-title {
        font-weight: 600;
      }
    }

    &-title {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      margin-bottom: 12px;
      white-space: normal;
      font-family: PingFangSC-Regular, PingFang SC;
    }
    // 查看原始题目
    &-origin {
      &-control {
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        color: #1890ff;
        line-height: 24px;
        margin-bottom: 25px;
        cursor: pointer;
      }
    }
    // 视频
    &-video {
      margin-bottom: 16px;
    }
    // TA的回答
    &-answer {
      margin-bottom: 25px;
    }
    // 胜任力
    &-capability {
      font-size: 14px;
      color: rgba(51, 51, 51, 0.85);
      line-height: 20px;
      margin-bottom: 11px;
      .title {
        font-weight: 600;
        margin-bottom: 8px;
      }
      .desc {
        font-weight: 400;
      }
    }
    // 维度列表
    &-score-list {
      padding-bottom: 2px;
      .score-item {
        font-size: 14px;
        font-weight: 400;
        color: rgba(51, 51, 51, 0.85);
        margin-bottom: 16px;
        .dot {
          width: 8px;
          height: 8px;
          background: #afb6ca;
          margin-right: 8px;
          border-radius: 4px;
        }
        .title {
          display: flex;
          align-items: center;
          color: #333333;
          line-height: 20px;
          margin-bottom: 8px;
        }
        .desc {
          color: #666666;
          line-height: 24px;
        }
      }
    }
    // 答题思路
    &-idea {
      font-size: 12px;
      color: #333333;
      line-height: 17px;
      background: #f0f3f7;
      border-radius: 10px;
      padding: 8px 16px;
      .title {
        font-weight: 600;
        margin-bottom: 2px;
      }
      .text {
        font-weight: 400;
      }
    }
  }
}
</style>