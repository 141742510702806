<template>
  <div class="expression-horizontal">
    <div class="bar">
      <p v-for="(item, index) in dataValue" :key="index" :style="`width: ${getPercent(item.value)}; background: ${item.color}`" class="bar-item" />
    </div>
    <div class="legend">
      <div v-for="(item, index) in dataValue" :key="index" class="legend-item">
        <span class="dot" :style="`background: ${item.color}`" />
        <span class="text">{{ item.name }}</span>
        <span class="rate">{{ getPercent(item.value) }}</span>
      </div>
    </div>
    <div v-if="rankScore.length" class="rank-container">
      <h4 class="title mt-36">以下为该候选人的主要表情（TOP3表情分析）：</h4>
      <div class="rank">
        <div v-for="(item, index) in rankScore" :key="index" class="rank-item">
          <span class="dot" :style="`background: ${item.color}`" />
          <span class="text">{{ item.emotionname }}</span>
          <span class="desc">{{ item.emotiondesc }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExpressionHorizontal',
  props: {
    emotionScore: {
      type: Array,
      default: () => [],
    },
    topEmotion: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: [],
      dataValue: [
        {
          name: '厌恶',
          value: 0,
          color: '#c8a3dd',
        },
        {
          name: '中性',
          value: 0,
          color: '#b0dce7',
        },
        {
          name: '难过',
          value: 0,
          color: '#dfe4e9',
        },
        {
          name: '愤怒',
          value: 0,
          color: '#c24040',
        },
        {
          name: '害怕',
          value: 0,
          color: '#6ed284',
        },
        {
          name: '高兴',
          value: 0,
          color: '#fdd926',
        },
        {
          name: '惊讶',
          value: 0,
          color: '#75e3ea',
        },
      ],
    }
  },
  computed: {
    // 总分
    totalScore() {
      return this.dataValue.map(item => item.value).reduce((pre, curr) => pre + curr)
    },
    // 排名
    rankScore() {
      return this.topEmotion.map(item => {
        return {
          ...item,
          color: this.dataValue.find(e => {
            return e.name === item.emotionname
          })?.color,
        }
      })
    },
  },
  created() {
    this.getScore()
  },
  methods: {
    // 获得分数
    getScore() {
      this.dataValue.forEach(item => (item.value = this.emotionScore.find(o => o.emotionname === item.name)?.emotioncount || 0))
    },
    // 获得百分比
    getPercent(value) {
      if (this.totalScore === 0) {
        return '0%'
      }
      return ((value / this.totalScore) * 100).toFixed(2) + '%'
    },
  },
}
</script>

<style lang="scss" scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.mt-36 {
  margin-top: 36px;
}
h4.title {
  font-size: 14px;
  font-weight: 600;
  color: rgba(51, 51, 51, 0.85);
  line-height: 20px;
  margin-bottom: 24px;
}
.expression-horizontal {
  .bar {
    display: flex;
    height: 18px;
    border-radius: 1px;
    background: #f7f7f9;
    margin-bottom: 16px;
  }
  .legend {
    display: flex;
    flex-wrap: wrap;
    &-item {
      display: flex;
      align-items: center;
      width: 33.3%;
      font-size: 14px;
      font-weight: 600;
      color: #333333;
      line-height: 24px;
      margin-bottom: 12px;
      .dot {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 8px;
      }
      .text {
        margin-right: 10px;
      }
    }
  }
  .rank-container {
    .rank {
      &-item {
        margin-bottom: 24px;
        font-size: 14px;
        line-height: 24px;
        color: #333333;
        .dot {
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 8px;
        }
        .text {
          font-weight: 600;
          margin-right: 24px;
        }
        .desc {
          font-weight: 400;
        }
      }
    }
  }
}
</style>
