<!-- 此页面暂不需要做国际化 -->
<template>
  <div class="perRepl5">
    <van-loading style="background-color: #fff;" v-if="loading" type="spinner" vertical color="#1989fa" text-color="#1989fa"> 加载中... </van-loading>
    <div class="cont" v-if="!loading">
      <div class="topdiv">
        <div class="flex tdsub pad05">
          <span class="mb4 txt">{{ interviewResult.position }}</span>
          <span class="tit">面试职位</span>
        </div>
        <div class="flex tdsub">
          <span class="mb4">
            <van-rate v-if="aiData && aiData.l5Star" v-model="aiData.l5Star" color="#ffd21e" void-icon="star" void-color="#eee" size="24px" readonly></van-rate>
            <template v-else>
              <span class="text">生成中</span>
            </template>
          </span>
          <span class="tit">内容得分</span>
        </div>
      </div>
      <div class="middiv">
        <video-problem-personal :mockType="interviewResult.mockType" :answer-result="answerResult" :answer-infos="answerInfos" :is-l5-position="isL5Position" />
        <div class="cards" v-if="interviewResult.mockType == 0">
          <div class="cardtop">
            <img src="../../assets/khimg.png" alt="" style="width:24px">
            <span class="tet">其他表现</span>
          </div>
          <div class="cardbot">
            <div class="carditm">
              <div class="citop">
                <span class="category">形象得分：</span>
                <span :class="['scores',!isMobile?'mw160':'']">
                  <van-rate v-if="aiData && aiData.beautyScore > 0" v-model="aiData.beautyScore" color="#ffd21e" void-icon="star" void-color="#eee" size="24px" readonly></van-rate>
                  <template v-else>
                    <span class="text">生成中</span>
                  </template>
                </span>
                <span class="grade" v-if="aiData && aiData.beautyScore > 0">{{ rating[aiData.beautyScore] }}</span>
              </div>
              <div class="cibot">
                <div class="word">{{ aiData.beautyScoreComment }}</div>
              </div>
            </div>
            <div class="carditm">
              <div class="citop">
                <span class="category">声音得分：</span>
                <span :class="['scores',!isMobile?'mw160':'']">
                  <van-rate v-if="aiData && aiData.speechScore > 0" v-model="aiData.speechScore" color="#ffd21e" void-icon="star" void-color="#eee" size="24px" readonly></van-rate>
                  <template v-else>
                    <span class="text">生成中</span>
                  </template>
                </span>
                <span class="grade" v-if="aiData && aiData.speechScore > 0">{{ rating[aiData.speechScore] }}</span>
              </div>
              <div class="cibot">
                <div class="word">{{ aiData.speechScoreComment }}</div>
              </div>
            </div>
            <div class="carditm">
              <div class="citop">
                <span class="category">表情得分：</span>
                <span :class="['scores',!isMobile?'mw160':'']">
                  <van-rate v-if="aiData && aiData.emotionScore > 0" v-model="aiData.emotionScore" color="#ffd21e" void-icon="star" void-color="#eee" size="24px" readonly></van-rate>
                  <template v-else>
                    <span class="text">生成中</span>
                  </template>
                </span>
                <span class="grade" v-if="aiData && aiData.emotionScore > 0">{{ rating[aiData.emotionScore] }}</span>
              </div>
              <div class="cibot">
                <div class="word">{{ aiData.emotionScoreComment }}</div>
                <div class="other">
                  <div class="ohitm" v-for="(itm,idx) in emotionScores.topemotion" :key="idx">
                    <img :src="require(`../../assets/emotes/${faceMap[itm.emotionname]}.png`)" alt="">
                    <span class="spw">{{ itm.emotionname }}</span>
                    <span class="spl">
                      <van-progress color="#033FFF" :percentage="itm.percnt" :show-pivot="false" stroke-width="8" />
                    </span>
                    <span class="spn">{{itm.percnt}}%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="botdiv">
        <p>版权声明</p>
        <span>本报告包含的所有内容（包括但不限于文本、数据、图片、图标、logo等）都受到商标法、知识产权法的保护，归苏州才干智因科技有限公司所有。未经苏州才干智因科技有限公司书面许可，任何人不得使用、修改、复制、翻译、公开及出售任何与本报告有关的内容及形式。</span>
      </div>
    </div>
  </div>
</template>

<script>
import VideoProblemPersonal from '@/components/personalReport/video-problem-personal.vue'
import { getInterviewInfos } from '@/api/candidate'
import { findId, report4MobileV2, interviewInfo } from '@/api/report.js'

export default {
  name: 'PersonalReportL5',
  components: {
    VideoProblemPersonal,
  },
  data() {
    return {
      isMobile: false,
      loading: true,
      info: {
        position: '', // 职位
        interviewId: '', // 作答记录id
        interviewName: '', // 作答记录name
        companyId: '', // 公司id
        positionId: '', // 职位id
      },
      answerOptions: [], // 作答记录选项
      aiData: {}, // AI报告信息
      interviewInfo: null, // 面试信息
      totalPoints: 0,
      rating: {
        1: '差',
        2: '较差',
        3: '一般',
        4: '良好',
        5: '优秀',
      },
      faceMap: {
        '中性': 1,
        '难过': 2,
        '惊讶': 3,
        '高兴': 4,
        '愤怒': 5,
        '厌恶': 6,
        '害怕': 7,
      },
    }
  },
  watch: {
    emotionScores(n) {
      if(n&&n.emtions) {
        //表情得分--总分
        this.totalPoints = n.emtions.map(item => item.emotioncount).reduce((pre, curr) => pre + curr);
      }
      if(n.topemotion?.length>0) {
        n.topemotion.forEach(e => {
          e.percnt = (+e.emotioncount>0 && +this.totalPoints>0) ? +((e.emotioncount / this.totalPoints) * 100).toFixed(2) : 0
        });
      }
    }
  },
  computed: {
    emotionScores() {
      const emotionScores = this.aiData.emotionScores
      if (typeof emotionScores === 'object') {
        return emotionScores
      } else {
        return {}
      }
    },
    jobseekerChannelId() {
      return this.$route.query.jobseekerChannelId
    },
    // l2报告信息
    l2ReportInfo() {
      return this.aiData?.l2ReportInfo || {}
    },
    // 问题答案
    answerInfos() {
      return this.l2ReportInfo.answerInfos || []
    },
    dimLabelList() {
      return this.l2ReportInfo.dimLabelList || []
    },
    isL5Position() {
      const { modetype } = this.$route.query
      return modetype === 'l5'
    },
    // 面试信息
    interviewResult() {
      return this.interviewInfo?.interviewResult || {}
    },
    // 题目信息
    answerResult() {
      return this.interviewInfo?.answerResult || []
    },
    // 雷达图的指标和极值
    indicatorArray() {
      return this.dimLabelList.map(item => ({
        name: item.assessmentPoint || '', // 指标
        score: Number(item.calculateStar).toFixed(0),
        max: 5,
      }))
    },
    // 雷达图的值
    seriesData() {
      return this.dimLabelList.map(item => Number(item.calculateStar) || 0)
    },
    // 雷达图的echarts配置
    radar() {
      return {
        indicator: this.indicatorArray,
        scale: true, // 是否是脱离 0 值比例。设置成 true 后坐标刻度不会强制包含零刻度。在双数值轴的散点图中比较有用。
        axisTick: {
          //刻度设置
          show: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#B1D8FF',
          },
        },
        axisName: {
          show: true,
          color: '#333',
          fontWeight: 'blod',
          formatter: function (value, indicator) {
            return value + `（${indicator.score}星）`
          },
        },
        splitNumber: 5, //刻度
        splitLine: {
          //刻度连接线
          show: false,
        },
        splitArea: {
          show: true,
          areaStyle: {
            color: ['rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)'],
          },
        },
      }
    },
    // 雷达图的echarts配置
    series() {
      return [
        {
          type: 'radar',
          label: {
            show: false,
          },
          data: [
            {
              value: this.seriesData,
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(90, 230, 191, 1)', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(24, 144, 255, 1)', // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
              lineStyle: {
                color: 'rgba(90, 230, 191, 1)',
                opacity: 1,
              },
              itemStyle: {
                color: '#409EFF',
              },
            },
          ],
        },
      ]
    },
    // 当前登录用户
    currentPerson() {
      return {
        positionId: this.info.positionId,
        interviewId: this.info.interviewId,
        jobseekerChannelId: this.jobseekerChannelId,
      }
    },
    isNewShareReport() {
      return this.$route.path === '/newShareReport' || this.$route.path === '/downloadReport' ? true : false
    },
    isXmzCompany() {
      const companyId = this.currentPerson?.companyId
      return this.$store.state.xmzCompanyId.includes(companyId)
    },
    isYJLCompany() {
      const companyId = this.currentPerson?.companyId
      return this.$store.state.yjlCompanyId.includes(companyId)
    },
    BQ() {
      return this.isYJLCompany ? 'BQ' : '表情'
    },
    SY() {
      return this.isYJLCompany ? 'SY' : '声音'
    },
    YZ() {
      return this.isYJLCompany ? 'YZ' : '职业形象'
    },
  },
  created() {
    this.getInterviewInfos() // 获取作答记录选项
    this.getCandidateInfo() // 获取候选人信息
  },
  mounted() {
    this.isMobile = /(mobile|nokia|iphone|ipad|android|samsung|htc|blackberry)/.test(navigator.userAgent.toLowerCase());
  },
  methods: {
    // 获取作答记录选项
    async getInterviewInfos() {
      const res = await getInterviewInfos({
        jobSeekerChannelId: this.jobseekerChannelId,
      })
      const { code, data } = res || {}
      if (code === 0) {
        this.answerOptions = data || []
        // 默认选中第一项
        const [firstOption = {}] = this.answerOptions
        const { interviewId, interviewName } = firstOption
        this.info.interviewId = interviewId
        this.info.interviewName = interviewName
      }
    },
    // 获取报告信息和面试信息
    async getInfo() {
      this.loading = true
      await Promise.all([this.getAIData(), this.getInterviewInfo()])
      this.loading = false
    },
    // 获取候选人基本信息
    async getCandidateInfo() {
      const { userId, jobseekerChannelId } = this.$route.query
      const res = await findId({ userId, jobseekerChannelId })
      const { code, data } = res || {}
      if (code === 0) {
        const { interviewId, companyId, positionid } = data || {}
        this.info.interviewId = interviewId || userId
        this.info.companyId = companyId
        this.info.positionId = positionid
        this.getInfo()
      }
    },
    // 获取报告信息
    async getAIData() {
      let interviewId = this.info.interviewId
      if(this.$route.query.userid) interviewId = this.$route.query.userid
      console.log(interviewId)
      const res = await report4MobileV2({
        interviewId: interviewId,
        jobSeekerChannelId: this.jobseekerChannelId,
      })
      const { code, data } = res || {}
      if (code === 0) {
        this.aiData = data || {}
      }
    },
    // 获取面试信息
    async getInterviewInfo() {
      let interviewId = this.info.interviewId
      if(this.$route.query.userid) interviewId = this.$route.query.userid
      const { companyId, positionId } = this.info
      const res = await interviewInfo({
        companyId,
        userId: interviewId,
        positionId,
        jobSeekerChannelId: this.jobseekerChannelId,
      })
      const { code, data, msg } = res || {}
      if (code === 0) {
        this.interviewInfo = data
      } else {
        this.$message.error(msg || '暂无维度')
      }
    },
    // 作答记录选项变化
    onAnswerChange(option = {}) {
      const { interviewId, interviewName } = option
      this.info.interviewId = interviewId
      this.info.interviewName = interviewName
      this.getInfo()
    },
  },
}
</script>

<style lang="scss" scoped>
:deep(.van-divider) {
  margin: 36px 0;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mt-36 {
  margin-top: 36px;
}
.text-right {
  text-align: right;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.perRepl5 {
  background: url('../../assets/shuiyin.png') repeat;
  /deep/.van-loading {
    margin-top: 43vh;
  }
  .cont {
    background: rgba(249, 250, 252, 0.9); // #F9FAFC;
    padding: 16px;
    // max-width: 1200px;
    // margin: 0 auto;
    .topdiv {
      height: 76px;
      display: flex;
      margin-bottom: 12px;
      border-radius: 16px;
      background: rgba(255, 255, 255, 0.5);
      .tdsub {
        width: 50%;
        flex-direction: column;
        .mb4 {
          margin-bottom: 4px;
        }
        .txt {
          width: 100%;
          padding: 0 3px;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          color: #000C21;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
        }
        .tit {
          color: rgba(0, 12, 33, 0.45);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }
      }
      .pad05 {
        padding: 0 5px;
      }
    }
    .middiv {
      .cards {
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-radius: 16px;
        overflow: hidden;
        background: rgba(255, 255, 255, 0.5);
        .cardtop {
          height: 58px;
          padding: 17px 16px;
          display: flex;
          align-items: center;
          background: linear-gradient(180deg, rgba(234, 239, 255, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
          img {
            width: 24px;
            margin-right: 8px;
          }
          .titl {
            color: rgba(0, 12, 33, 0.65);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }
          .tet {
            color: #000C21;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
          }
        }
        .carcen {
          margin: 0 16px;
          overflow: hidden;
          margin-bottom: 38px;
          border-radius: 16px;
          border: 1px solid rgba(0, 12, 33, 0.08);
          .carcentop {
            height: 48px;
            display: flex;
            padding: 0 16px;
            align-items: center;
            background: #FFF;
            background: linear-gradient(90deg, #F8E3C6 0%, #F2BD74 100%);
            img {
              width: 16px;
            }
            .tite {
              color: #935C19;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              margin: 0 4px;
            }
          }
          .suggest {
            color: #CD9955;
            text-align: justify;
            font-family: PingFang SC;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; 
            padding: 12px 16px;
            position: relative;
            overflow: hidden;
            border-radius: 0 0 16px 16px;
            .mask {
              width: 100%;
              height: 100%;
              border-radius: 0 0 16px 16px;
              overflow: hidden;
              position: absolute;
              top: 0;
              left: 0;
              backdrop-filter: blur(4px);
              background: rgba(255, 255, 255, 0.45);
              .unlock {
                height: 30px;
                padding: 0 12px;
                border-radius: 38px;
                background: linear-gradient(45deg, #F7E1C5 0%, #F2BB73 100%);
                span {
                  color: #744E1F;
                  text-align: justify;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px;
                  margin-left: 6px;
                }
              }
            }
          }
        }
        .issue {
          margin: 0 16px;
          margin-bottom: 30px;
          border-radius: 16px;
          background: rgba(249, 250, 252, 0.9); //#F4F7FF;
          position: relative;
          .lab {
            height: 28px;
            padding: 0 12px;
            position: absolute;
            left: 0;
            top: -14px;
            text-align: center;
            line-height: 28px;
            background: linear-gradient(225deg, #033FFF 0%, #48A7FF 100%);
            border-radius: 16px 16px 16px 4px;
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
          }
          .iscot {
            color: rgba(0, 12, 33, 0.85);
            text-align: justify;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            padding: 26px 16px 12px;
          }
        }
        .answer {
          margin: 0 16px;
          border-radius: 16px;
          background: rgba(255, 250, 241, 0.5); //#FFFAF1;
          position: relative;
          .lab {
            width: 88px;
            height: 28px;
            position: absolute;
            right: 0;
            top: -14px;
            text-align: center;
            line-height: 28px;
            background: linear-gradient(225deg, #FFA216 0%, #FFC700 100%);
            border-radius: 16px 16px 4px 16px;
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
          }
          .iscot {
            color: rgba(0, 12, 33, 0.85);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            padding: 26px 16px 12px;
            .word {
              height: 66px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 3;
              text-align: justify;
            }
            .heightAuto {
              height: auto;
              display: inherit;
            }
            .playback {
              margin-top: 16px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .pbkbtn {
                width: 100px;
                height: 30px;
                cursor: pointer;
                border-radius: 36px;
                border: 1px solid #FFA714;
                span {
                  color: #FFA714;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  margin-right: 4px;
                  position: relative;
                  top: 1px;
                }
              }
              .expand {
                display: flex;
                align-items: center;
                cursor: pointer;
                span {
                  color: #FFA714;
                  font-family: PingFang SC;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px;
                  margin-right: 4px;
                }
                img {
                  width: 16px;
                  height: 16px;
                }
                .reversal {
                  transform: rotate(180deg);
                }
              }
            }
            .koyou {
              justify-content: end;
              .pbkbtn {
                margin-right: 16px;
              }
            }
          }
        }
        .cardbot {
          .carditm {
            margin: 0 16px 16px;
            &:last-child {
              margin-bottom: 0;
            }
            .citop {
              margin-bottom: 13px;
              display: flex;
              align-items: center;
              .category {
                color: #000C21;
                text-align: justify;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
              }
              .scores {
                flex: 1;
              }
              .mw160 {
                max-width: 160px;
              }
              .grade {
                color: rgba(0, 12, 33, 0.65);
                font-size: 14px;
                font-weight: 400;
              }
            }
            .cibot {
              padding: 12px 16px;
              border-radius: 16px 4px 16px 16px;
              background:rgba(249, 250, 252, 0.3);// #F4F7FF;
              .word {
                color: rgba(0, 12, 33, 0.85);
                text-align: justify;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
              }
              .other {
                padding: 16px;
                border-radius: 8px;
                background: rgba(255, 255, 255, 0.5);
                .ohitm {
                  display: flex;
                  align-items: center;
                  margin-bottom: 16px;
                  &:last-child {
                    margin-bottom: 0;
                  }
                  img {
                    width: 25px;
                    height: 25px;
                  }
                  .spw {
                    color: rgba(0, 12, 33, 0.85);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    margin: 0 16px 0 8px;
                  }
                  .spl {
                    flex: 1;
                    max-width: 120px;
                    margin-right: 16px;
                  }
                  .spn {
                    color: rgba(0, 12, 33, 0.85);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22px; 
                  }
                }
              }
            }
          }
        }
      }
    }
    .botdiv {
      padding: 16px;
      border-radius: 16px;
      background: rgba(255, 255, 255, 0.5);
      p {
        color: #000C21;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 8px;
      }
      span {
        color: rgba(0, 0, 0, 0.45);
        text-align: justify;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}
.wrapper {
  display: none;
  * {
    box-sizing: border-box;
  }
  .van-loading {
    position: absolute;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  .personal-report {
    width: 100%;
    margin: 0 auto;
    overflow: auto;
    header {
      background: url('~@/assets/images/personal-report-banner.png') top left;
      background-size: 100% 100%;
      padding: 24px;
      h1.title {
        text-align: center;
        font-size: 20px;
        line-height: 20px;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 19px;
      }
      .divider {
        height: 1px;
        border: 1px solid #ffffff;
      }
      .basic-info {
        justify-content: space-between;
        margin: 17px 0 0;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        &-item > p {
          margin-bottom: 8px;
        }
      }
      .select-container {
        margin-bottom: 17px;
        :deep(.van-cell) {
          padding: 2px 10px;
          background: transparent;
          border-radius: 3px;
          border: 1px solid #ffffff;
          .van-field__control {
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            line-height: 20px;
          }
          .van-field__right-icon {
            color: #fff;
            line-height: 1;
            .van-icon {
              font-size: 14px;
            }
          }
        }
      }
    }
    section {
      min-height: calc(100vh - 180px);
      background-color: #F9FAFC;
      padding: 40px 24px;

      .column-reverse {
        display: flex;
        flex-direction: column-reverse;
      }
      .module-card {
        margin-bottom: 36px;
        .module-title {
          font-size: 20px;
          font-weight: 600;
          color: #333333;
          line-height: 28px;
          margin-bottom: 24px;
          &.left-icon {
            position: relative;
            padding-left: 10px;
            &::before {
              content: '';
              position: absolute;
              width: 4px;
              height: 18px;
              background: #1890ff;
              border-radius: 2px;
              left: -0;
              top: 4px;
            }
          }
        }
        .module-content {
          h2.title {
            font-size: 16px;
            font-weight: 600;
            color: rgba(51, 51, 51, 0.85);
            line-height: 22px;
            margin-bottom: 8px;
          }
          h3.title {
            font-size: 14px;
            font-weight: 400;
            color: rgba(51, 51, 51, 0.85);
            line-height: 20px;
            margin-bottom: 30px;
          }
          h4.title {
            font-size: 14px;
            font-weight: 600;
            color: rgba(51, 51, 51, 0.85);
            line-height: 20px;
            margin-bottom: 8px;
            &.mb-24 {
              margin-bottom: 24px;
            }
          }
          // AI打分
          .total-score {
            margin-bottom: 36px;
            &-area {
              margin-bottom: 24px;
              .desc {
                margin-right: 15px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(98, 112, 152, 0.65);
                line-height: 20px;
                margin-bottom: 16px;
              }
              .card {
                background: #ffffff;
                box-shadow: 2px 2px 10px 0px rgba(169, 193, 205, 0.34);
                border-radius: 3px;
                padding: 20px;
                font-size: 14px;
                font-weight: 600;
                color: #333333;
                line-height: 24px;
                justify-content: space-between;

                &.space {
                  margin: 0.266667rem auto;
                }
                .text {
                  font-weight: 400;
                }
              }
            }
            // 建议文案
            .copywriting {
              font-size: 14px;
              font-weight: 400;
              color: rgba(51, 51, 51, 0.85);
              line-height: 20px;
              margin-bottom: 24px;
            }
            // AI总分排名
            .rank-container {
              margin-bottom: 17px;
            }
            // 图表
            .chart-container {
              .chart-legend {
                flex-wrap: wrap;
                justify-content: space-between;
                margin-bottom: 17px;
                &-item {
                  justify-content: flex-start;
                  width: 35%;
                  margin-bottom: 12px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #333333;
                  line-height: 24px;
                  white-space: nowrap;
                  .dot {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    background: #1890ff;
                    border-radius: 6px;
                    margin-right: 8px;
                  }
                }
              }
              .chart {
                height: 250px;
                margin-bottom: 36px;
              }
            }
            // 各种维度得分情况
            .score-list {
              &-item {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                .dimension-desc {
                  color: rgba(51, 51, 51, 0.85);
                  margin-bottom: 24px;
                }
                .comment {
                  color: #333333;
                  margin-top: 17px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
