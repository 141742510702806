import service from '@/config/http'
//import qs from 'qs'

export const checkReportPwd = data => {
  const req = {
    url: '/candidate/employment/interview/checkReportPwd',
    data,
    method: 'post',
  }
  return service(req)
}
