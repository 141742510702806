<!-- 此页面暂不需要做国际化 -->
<template>
  <div class="wrapper">
    <van-loading v-if="loading" type="spinner" vertical color="#1989fa" text-color="#1989fa"> 加载中... </van-loading>
    <div class="personal-report" v-if="!loading">
      <header>
        <h1 class="title">{{ interviewResult.position }}</h1>
        <div class="divider" />
        <div class="basic-info flex">
          <div class="basic-info-item">
            <p class="label">报告日期：</p>
            <p>{{ interviewResult.updatetime }}</p>
          </div>
          <div class="basic-info-item text-right">
            <p class="label">报告人：</p>
            <p>{{ interviewResult.name }}</p>
          </div>
        </div>
        <div v-if="answerOptions.length > 1" class="select-container">
          <van-field :value="info.interviewName" placeholder="作答记录" readonly clickable @click="showAnswerPicker = true">
            <template #right-icon>
              <van-icon :name="showAnswerPicker ? 'arrow-up' : 'arrow-down'" />
            </template>
          </van-field>
          <van-popup v-model="showAnswerPicker" round position="bottom">
            <van-picker show-toolbar :columns="answerOptions" value-key="interviewName" @cancel="showAnswerPicker = false" @confirm="onAnswerChange" />
          </van-popup>
        </div>
      </header>
      <section>
        <div :class="{ 'column-reverse': isL5Position }">
          <!-- 整体表现 -->
          <div class="module-card">
            <div class="module-title left-icon">整体表现</div>
            <div class="module-content">
              <div class="total-score">
                <h2 class="title">AI打分：</h2>
                <div class="total-score-area">
                  <p class="desc">基于企业岗位的胜任力的要求，利用Ai技术对候选人的核心潜力（AI T-DNA）、表情、声音等进行多模态分析得出的综合评价。</p>
                  <div class="card flex" v-if="!isL5Position">
                    <span>AI视频面试总分：</span>
                    <van-rate v-if="isOpenL2 && l2ReportInfo.l2score" v-model="l2ReportInfo.l2score" color="#ffd21e" void-icon="star" void-color="#eee" size="24px" readonly></van-rate>
                    <template v-else>
                      <span v-if="l2ReportInfo.l2status" class="text">生成中</span>
                      <span v-else>-</span>
                    </template>
                  </div>

                  <template v-else>
                    <div class="card flex space" style="">
                      <span>AI视频总分：</span>
                      <van-rate v-if="aiData && aiData.l5Star" v-model="aiData.l5Star" color="#ffd21e" void-icon="star" void-color="#eee" size="24px" readonly></van-rate>
                      <template v-else>
                        <span class="text">生成中</span>
                      </template>
                    </div>

                    <div class="card flex space">
                      <span>AI-回答质量：</span>
                      <van-rate v-if="aiData && aiData.tdnaScore > 0" v-model="aiData.tdnaScore" color="#ffd21e" void-icon="star" void-color="#eee" size="24px" readonly></van-rate>
                      <template v-else>
                        <span class="text">生成中</span>
                      </template>
                    </div>

                    <div class="card flex space">
                      <span>AI-声音：</span>
                      <van-rate v-if="aiData && aiData.speechScore > 0" v-model="aiData.speechScore" color="#ffd21e" void-icon="star" void-color="#eee" size="24px" readonly></van-rate>
                      <template v-else>
                        <span class="text">生成中</span>
                      </template>
                    </div>

                    <div class="card flex space">
                      <span>AI-表情：</span>
                      <van-rate v-if="aiData && aiData.emotionScore > 0" v-model="aiData.emotionScore" color="#ffd21e" void-icon="star" void-color="#eee" size="24px" readonly></van-rate>
                      <template v-else>
                        <span class="text">生成中</span>
                      </template>
                    </div>

                    <div class="card flex space">
                      <span>AI-职业形象：</span>
                      <van-rate v-if="aiData && aiData.beautyScore > 0" v-model="aiData.beautyScore" color="#ffd21e" void-icon="star" void-color="#eee" size="24px" readonly></van-rate>
                      <template v-else>
                        <span class="text">生成中</span>
                      </template>
                    </div>
                  </template>
                </div>
                <!-- 建议文案 -->
                <p v-show="aiData.l2TotalScoreComment" class="copywriting">{{ aiData.l2TotalScoreComment }}</p>
                <van-divider />
                <template v-if="!isL5Position">
                  <div v-if="isOpenL2 && l2ReportInfo.l2status === 0" class="rank-container">
                    <h3 class="title">AI 总分排名占整体候选人情况：</h3>
                    <percent-wrapper :sort="l2ReportInfo.l2PercentageScore || 0" />
                  </div>
                </template>
                <!-- 图表 -->
                <div class="chart-container">
                  <div v-if="!isL5Position" class="chart-legend flex">
                    <p v-for="(text, index) in chartLegends" :key="index" class="chart-legend-item flex">
                      <span class="dot" />
                      <span>{{ text }}</span>
                    </p>
                  </div>
                  <div v-show="showRadar" ref="radar" class="chart" />
                </div>
                <div class="score-list">
                  <div v-if="!isXmzCompany" class="score-list-item">
                    <!-- AI 职业形象 -->
                    <h4 class="title">{{ isYJLCompany ? 'AI-' : 'L2-AI' }}{{ YZ }}分析得分：</h4>
                    <p class="dimension-desc">基于人工智能的人脸识别算法对候选人的五官和轮廓的综合判断打分</p>
                    <process-wrapper :score="aiData.beautyScore || 0" type="five" />
                    <p v-show="aiData.beautyScoreComment" class="comment">{{ aiData.beautyScoreComment }}</p>
                    <van-divider />
                  </div>
                  <div class="score-list-item">
                    <!-- AI 声音 -->
                    <h4 class="title">{{ isYJLCompany ? 'AI-' : 'L2-AI' }}{{ SY }}分析得分：</h4>
                    <p class="dimension-desc">基于自主研发领先人工智能语音算法对候选人回答面试问题时的音量、音调等五大声音维度进行综合分析，从而判断候选人在语音表达上的水平</p>
                    <process-wrapper :score="aiData.speechScore || 0" type="five" />
                    <p v-show="aiData.speechScoreComment" class="comment">{{ aiData.speechScoreComment }}</p>
                    <van-divider />
                  </div>
                  <div class="score-list-item">
                    <!-- AI 表情 -->
                    <h4 class="title">{{ isYJLCompany ? 'AI-' : 'L2-AI' }}{{ BQ }}得分：</h4>
                    <p class="dimension-desc">基于宏表情分析的计算机视觉算法对候选人情绪和回答问题真实性的综合判断得分</p>
                    <process-wrapper :score="aiData.emotionScore || 0" type="five" />
                    <p v-show="aiData.emotionScoreComment" class="comment">{{ aiData.emotionScoreComment }}</p>
                    <div v-if="aiData.emotionScore">
                      <!-- AI表情 -->
                      <h4 class="title mt-36 mb-24">{{ isYJLCompany ? 'AI-' : 'L2-AI' }}{{ BQ }}分析详情：</h4>
                      <expression-horizontal :emotion-score="emotionScores.emtions || []" :top-emotion="emotionScores.topemotion || []" />
                    </div>
                    <van-divider />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 单题表现 -->
          <div class="module-card">
            <div class="module-title left-icon">单题表现</div>
            <!-- 视频题 -->
            <video-problem-personal :answer-result="answerResult" :answer-infos="answerInfos" :is-l5-position="isL5Position" />
          </div>
        </div>

        <copyright-notice v-if="isL5Position" style="margin-top: 0"></copyright-notice>
      </section>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import PercentWrapper from '@/components/reportV3/common/percent-wrapper.vue'
import ProcessWrapper from '@/components/reportV3/common/process-wrapper.vue'
import ExpressionHorizontal from '@/components/personalReport/expressionHorizontal.vue'
import VideoProblemPersonal from '@/components/personalReport/video-problem-personal.vue'
import CopyrightNotice from './copyright-notice.vue'
import { getInterviewInfos } from '@/api/candidate'
import { findId, report4MobileV2, interviewInfo } from '@/api/report.js'

export default {
  name: 'PersonalReport',
  components: {
    PercentWrapper,
    ProcessWrapper,
    ExpressionHorizontal,
    VideoProblemPersonal,
    CopyrightNotice,
  },
  data() {
    return {
      loading: true,
      info: {
        position: '', // 职位
        interviewId: '', // 作答记录id
        interviewName: '', // 作答记录name
        companyId: '', // 公司id
        positionId: '', // 职位id
      },
      showAnswerPicker: false, // 作答记录picker
      answerOptions: [], // 作答记录选项
      aiData: {}, // AI报告信息
      interviewInfo: null, // 面试信息
      chartLegends: ['1星  0%-20%', '2星  21%-40%', '3星  41%-60%', '4星  61%-80%', '5星  81%-100%'],
    }
  },
  computed: {
    emotionScores() {
      const emotionScores = this.aiData.emotionScores

      if (typeof emotionScores === 'object') {
        return emotionScores
      } else {
        return {}
      }
    },
    jobseekerChannelId() {
      return this.$route.query.jobseekerChannelId
    },
    // l2报告信息
    l2ReportInfo() {
      return this.aiData?.l2ReportInfo || {}
    },
    // 问题答案
    answerInfos() {
      return this.l2ReportInfo.answerInfos || []
    },
    dimLabelList() {
      return this.l2ReportInfo.dimLabelList || []
    },
    isL5Position() {
      const { modetype } = this.$route.query
      return modetype === 'l5'
    },
    // 是否开启L2
    isOpenL2() {
      // TODO: 需要设置companyInfo
      const isOrderAi = this.aiData?.isOrderAi || this.$store.state.companyInfo?.isOrderAi
      return isOrderAi === 2 || isOrderAi === 3
    },
    // 面试信息
    interviewResult() {
      return this.interviewInfo?.interviewResult || {}
    },
    // 题目信息
    answerResult() {
      return this.interviewInfo?.answerResult || []
    },
    // 雷达图的指标和极值
    indicatorArray() {
      return this.dimLabelList.map(item => ({
        name: item.assessmentPoint || '', // 指标
        score: Number(item.calculateStar).toFixed(0),
        max: 5,
      }))
    },
    // 雷达图的值
    seriesData() {
      return this.dimLabelList.map(item => Number(item.calculateStar) || 0)
    },
    // 是否显示雷达图
    showRadar() {
      return this.dimLabelList.length > 2
    },
    // 雷达图的echarts配置
    radar() {
      return {
        indicator: this.indicatorArray,
        scale: true, // 是否是脱离 0 值比例。设置成 true 后坐标刻度不会强制包含零刻度。在双数值轴的散点图中比较有用。
        axisTick: {
          //刻度设置
          show: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#B1D8FF',
          },
        },
        axisName: {
          show: true,
          color: '#333',
          fontWeight: 'blod',
          formatter: function (value, indicator) {
            return value + `（${indicator.score}星）`
          },
        },
        splitNumber: 5, //刻度
        splitLine: {
          //刻度连接线
          show: false,
        },
        splitArea: {
          show: true,
          areaStyle: {
            color: ['rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)'],
          },
        },
      }
    },
    // 雷达图的echarts配置
    series() {
      return [
        {
          type: 'radar',
          label: {
            show: false,
          },
          data: [
            {
              value: this.seriesData,
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(90, 230, 191, 1)', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(24, 144, 255, 1)', // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
              lineStyle: {
                color: 'rgba(90, 230, 191, 1)',
                opacity: 1,
              },
              itemStyle: {
                color: '#409EFF',
              },
            },
          ],
        },
      ]
    },
    // 当前登录用户
    currentPerson() {
      return {
        positionId: this.info.positionId,
        interviewId: this.info.interviewId,
        jobseekerChannelId: this.jobseekerChannelId,
      }
    },
    isNewShareReport() {
      return this.$route.path === '/newShareReport' || this.$route.path === '/downloadReport' ? true : false
    },
    isXmzCompany() {
      const companyId = this.currentPerson?.companyId
      return this.$store.state.xmzCompanyId.includes(companyId)
    },
    isYJLCompany() {
      const companyId = this.currentPerson?.companyId
      return this.$store.state.yjlCompanyId.includes(companyId)
    },
    BQ() {
      return this.isYJLCompany ? 'BQ' : '表情'
    },
    SY() {
      return this.isYJLCompany ? 'SY' : '声音'
    },
    YZ() {
      return this.isYJLCompany ? 'YZ' : '职业形象'
    },
  },
  created() {
    this.getInterviewInfos() // 获取作答记录选项
    this.getCandidateInfo() // 获取候选人信息
  },
  methods: {
    // 获取作答记录选项
    async getInterviewInfos() {
      const res = await getInterviewInfos({
        jobSeekerChannelId: this.jobseekerChannelId,
      })
      const { code, data } = res || {}
      if (code === 0) {
        this.answerOptions = data || []
        // 默认选中第一项
        const [firstOption = {}] = this.answerOptions
        const { interviewId, interviewName } = firstOption
        this.info.interviewId = interviewId
        this.info.interviewName = interviewName
      }
    },
    // 获取报告信息和面试信息
    async getInfo() {
      this.loading = true
      await Promise.all([this.getAIData(), this.getInterviewInfo()])
      this.loading = false
      this.$nextTick(() => {
        if (this.answerInfos.length) {
          this.initChart()
        }
      })
    },
    // 获取候选人基本信息
    async getCandidateInfo() {
      const { userId, jobseekerChannelId } = this.$route.query
      const res = await findId({ userId, jobseekerChannelId })
      const { code, data } = res || {}
      if (code === 0) {
        const { interviewId, companyId, positionid } = data || {}
        this.info.interviewId = interviewId || userId
        this.info.companyId = companyId
        this.info.positionId = positionid
        this.getInfo()
      }
    },
    // 获取报告信息
    async getAIData() {
      const res = await report4MobileV2({
        interviewId: this.info.interviewId,
        jobSeekerChannelId: this.jobseekerChannelId,
      })
      const { code, data } = res || {}
      if (code === 0) {
        this.aiData = data || {}
      }
    },
    // 获取面试信息
    async getInterviewInfo() {
      const { companyId, interviewId, positionId } = this.info
      const res = await interviewInfo({
        companyId,
        userId: interviewId,
        positionId,
        jobSeekerChannelId: this.jobseekerChannelId,
      })
      const { code, data, msg } = res || {}
      if (code === 0) {
        this.interviewInfo = data
      } else {
        this.$message.error(msg || '暂无维度')
      }
    },
    // 作答记录选项变化
    onAnswerChange(option = {}) {
      const { interviewId, interviewName } = option
      this.info.interviewId = interviewId
      this.info.interviewName = interviewName
      this.getInfo()
    },
    // 初始化图表
    async initChart() {
      const dom = this.$refs.radar
      let myChart = echarts.getInstanceByDom(dom)
      if (!myChart) {
        // 判断是否已经被初始化
        myChart = echarts.init(dom)
      }
      const option = {
        radar: this.radar,
        series: this.series,
      }
      myChart.setOption(option)
    },
  },
}
</script>

<style lang="scss" scoped>
:deep(.van-divider) {
  margin: 36px 0;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mt-36 {
  margin-top: 36px;
}
.text-right {
  text-align: right;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.wrapper {
  * {
    box-sizing: border-box;
  }
  .van-loading {
    position: absolute;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  .personal-report {
    width: 100%;
    margin: 0 auto;
    overflow: auto;
    header {
      background: url('~@/assets/images/personal-report-banner.png') top left;
      background-size: 100% 100%;
      padding: 24px;
      h1.title {
        text-align: center;
        font-size: 20px;
        line-height: 20px;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 19px;
      }
      .divider {
        height: 1px;
        border: 1px solid #ffffff;
      }
      .basic-info {
        justify-content: space-between;
        margin: 17px 0 0;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        &-item > p {
          margin-bottom: 8px;
        }
      }
      .select-container {
        margin-bottom: 17px;
        :deep(.van-cell) {
          padding: 2px 10px;
          background: transparent;
          border-radius: 3px;
          border: 1px solid #ffffff;
          .van-field__control {
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            line-height: 20px;
          }
          .van-field__right-icon {
            color: #fff;
            line-height: 1;
            .van-icon {
              font-size: 14px;
            }
          }
        }
      }
    }
    section {
      min-height: calc(100vh - 180px);
      background-color: #fff;
      padding: 40px 24px;

      .column-reverse {
        display: flex;
        flex-direction: column-reverse;
      }
      .module-card {
        margin-bottom: 36px;
        .module-title {
          font-size: 20px;
          font-weight: 600;
          color: #333333;
          line-height: 28px;
          margin-bottom: 24px;
          &.left-icon {
            position: relative;
            padding-left: 10px;
            &::before {
              content: '';
              position: absolute;
              width: 4px;
              height: 18px;
              background: #1890ff;
              border-radius: 2px;
              left: -0;
              top: 4px;
            }
          }
        }
        .module-content {
          h2.title {
            font-size: 16px;
            font-weight: 600;
            color: rgba(51, 51, 51, 0.85);
            line-height: 22px;
            margin-bottom: 8px;
          }
          h3.title {
            font-size: 14px;
            font-weight: 400;
            color: rgba(51, 51, 51, 0.85);
            line-height: 20px;
            margin-bottom: 30px;
          }
          h4.title {
            font-size: 14px;
            font-weight: 600;
            color: rgba(51, 51, 51, 0.85);
            line-height: 20px;
            margin-bottom: 8px;
            &.mb-24 {
              margin-bottom: 24px;
            }
          }
          // AI打分
          .total-score {
            margin-bottom: 36px;
            &-area {
              margin-bottom: 24px;
              .desc {
                margin-right: 15px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(98, 112, 152, 0.65);
                line-height: 20px;
                margin-bottom: 16px;
              }
              .card {
                background: #ffffff;
                box-shadow: 2px 2px 10px 0px rgba(169, 193, 205, 0.34);
                border-radius: 3px;
                padding: 20px;
                font-size: 14px;
                font-weight: 600;
                color: #333333;
                line-height: 24px;
                justify-content: space-between;

                &.space {
                  margin: 0.266667rem auto;
                }
                .text {
                  font-weight: 400;
                }
              }
            }
            // 建议文案
            .copywriting {
              font-size: 14px;
              font-weight: 400;
              color: rgba(51, 51, 51, 0.85);
              line-height: 20px;
              margin-bottom: 24px;
            }
            // AI总分排名
            .rank-container {
              margin-bottom: 17px;
            }
            // 图表
            .chart-container {
              .chart-legend {
                flex-wrap: wrap;
                justify-content: space-between;
                margin-bottom: 17px;
                &-item {
                  justify-content: flex-start;
                  width: 35%;
                  margin-bottom: 12px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #333333;
                  line-height: 24px;
                  white-space: nowrap;
                  .dot {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    background: #1890ff;
                    border-radius: 6px;
                    margin-right: 8px;
                  }
                }
              }
              .chart {
                height: 250px;
                margin-bottom: 36px;
              }
            }
            // 各种维度得分情况
            .score-list {
              &-item {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                .dimension-desc {
                  color: rgba(51, 51, 51, 0.85);
                  margin-bottom: 24px;
                }
                .comment {
                  color: #333333;
                  margin-top: 17px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
