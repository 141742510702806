<template>
  <div class="account-pass">
    <van-form @submit="onSubmit">
      <van-field
        v-model="phone"
        :rules="[{ required: true, message: $t('view.report.login.text013') }]"
        label-width="3.2em"
        type=""
        name="手机号"
        :label="$t('view.report.login.text012')"
        :placeholder="$t('view.report.login.text012')"
      ></van-field>
      <van-field
        v-model="password"
        :rules="[{ required: true, message: $t('view.report.login.text015') }]"
        label-width="3.2em"
        type="password"
        name="密码"
        :label="$t('view.report.login.text014')"
        :placeholder="$t('view.report.login.text014')"
      ></van-field>
      <van-button round block type="info" native-type="submit" size="normal" class="submit-btn">{{ $t('view.report.login.text016') }}</van-button>
    </van-form>
  </div>
</template>
<script>
import { login } from '@/api/admin'
import { charToUnicode } from '@/utils'
export default {
  data() {
    return {
      phone: '',
      password: '',
    }
  },
  methods: {
    onSubmit() {
      console.log('submit')
      const data = {
        prefixPhone: '86',
        email: '',
        account: this.phone,
        vcode: '',
        password: charToUnicode(this.password),
      }
      login(data).then(res => {
        if (res && res.code === 0) {
          this.$emit('confirmLogin', res.data.token)
        } else {
          this.$notify({ type: 'danger', message: res.msg })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.account-pass {
  .van-form {
    margin-bottom: 20px;
    .submit-btn {
      width: 80%;
      margin: 20px auto 0;
    }
  }
}
</style>
